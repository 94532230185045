<script lang="ts" setup>
import type { HomepageCardBannerLogoProps } from './HomepageCardBannerLogo.props'

const props = defineProps<HomepageCardBannerLogoProps>()

const logoIcons = computed(() =>
  props.bannerLogoType === 'extended' ? logoMap : menuLogoMap
)

const imageClasses = 'ml:px-0 h-full w-full object-contain'
</script>

<template>
  <div
    class="logo-wrapper homepage-logo-banner flex items-center justify-center"
  >
    <UIMedia v-if="logo" v-bind="logo" :class="imageClasses" />
    <component
      :is="logoIcons[brand]"
      v-if="brand"
      aria-hidden="true"
      class="px-[5%]"
      :class="imageClasses"
    />
  </div>
</template>
